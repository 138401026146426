// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api:"https://coaching.etutorials.co.in/web/",
  imgpath:"https://coaching.etutorials.co.in/images/",
  socials:[
    {
      name:"facebook",
      link:"https://www.facebook.com/"
    },
    {
      name:"twitter",
      link:"https://www.twitter.com/"
    },
    {
      name:"linkedin",
      link:"https://www.linkedin.com/"
    },
    {
      name:"instagram",
      link:"https://www.instagram.com/"
    }
  ],
  contact:[
    {
      name:"call",
      text:"+91 8130202879",
      link:"tel:+918130202879"
    },
    {
      name:"mail",
      text:"optiwari.india@gmail.com",
      link:"mailto:optiwari.india@gmail.com"
    }
  ],
  why:{
    text:"The ship set ground on the shore of this uncharted desert isle with Gilligan the Skipper too the millionaire and his wife. These days are all Happy and Free. These days are all share them with me. It's time to put on makeup. It's time to dress up right. It's time to raise the curtain on the Muppet Show tonight.",
    heading:"Fusce sem dolor, interdum in efficitur at, faucibus nec lorem."
  },
  video:{
    heading:"Enjoy a great students community",
    text:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil recusandae repellendus iure, quos et sit vero deserunt numquam cum soluta."
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
