import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./page/home/home.module').then( m => m.HomePageModule)},
  {
    path: 'about-us',
    loadChildren: () => import('./page/about-us/about-us.module').then( m => m.AboutUsPageModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./page/news/news.module').then( m => m.NewsPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./page/contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'upcoming-courses',
    loadChildren: () => import('./page/upcoming-courses/upcoming-courses.module').then( m => m.UpcomingCoursesPageModule)
  },
  {
    path: 'policy/:name',
    loadChildren: () => import('./page/policy/policy.module').then( m => m.PolicyPageModule)
  },
  {
    path: 'gallery',
    loadChildren: () => import('./page/gallery/gallery.module').then( m => m.GalleryPageModule)
  },
  {
    path: 'students-zone',
    loadChildren: () => import('./page/students-zone/students-zone.module').then( m => m.StudentsZonePageModule)
  },
  {
    path: 'view-news',
    loadChildren: () => import('./view-news/view-news.module').then( m => m.ViewNewsPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
